import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { AnimationFadeIn } from 'shared/Animation/Animation'

interface Datas {
  markdownRemark: {
    frontmatter: {
      top: {
        title: string
        image: {
          alt: string
          src: any
          width: number
          height: number
        }
      }
    }
  }
}

const s = {
  container: `w-top fixed bottom-48 z-10 flex justify-end top:w-11/12`,
  button: `no-underline bg-transparent border-0 curser-pointer focus:outline-none`,
  image: `w-9 h-9 filtercolor`,
}

const Top = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          top {
            title
            image {
              alt
              width
              height
              src {
                publicURL
              }
            }
          }
        }
      }
    }
  `) as Datas

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const { title, image } = datas.markdownRemark.frontmatter.top

  return (
    <section className={`${s.container}`}>
      <button className={s.button} title={title} onClick={() => handleClick()}>
        <AnimationFadeIn delay={100} x={100} y={0} shows={false}>
          <img
            className={s.image}
            src={image.src.publicURL}
            alt={image.alt}
            width={image.width}
            height={image.height}
          />
        </AnimationFadeIn>
      </button>
    </section>
  )
}

export default Top
