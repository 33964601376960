import React, { useEffect } from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { useMenuContext } from 'hooks/menu-context'

import Logo from 'shared/Logo/Logo'
import Hamburger from 'main/Header/Hamburger'
import Social from 'shared/Social/Social'

interface Datas {
  datas: {
    allMarkdownRemark: {
      edges: {
        node: {
          frontmatter: {
            nav: {
              id: number
              slug: string
              title: string
              link: string
            }[]
          }
        }
      }[]
    }
  }
}

const s = {
  nav: `w-full h-24 flex align-center`,
  list: (click: any) => `
  ${
    click
      ? `mdx:visible mdx:opacity-100 mdx:invert-0`
      : `mdx:invisible mdx:opacity-0 mdx:invert`
  }
  relative list-none w-full h-full flex justify-end items-center overflow-hidden reserve reserve_nav transition delay-300 ease-out duration-500 mdx:fixed mdx:top-0 mdx:left-0 mdx:w-full mdx:h-full mdx:flex-col mdx:justify-center mdx:text-center mdx:bg-white `,

  items: `mr-12 capitalize overflow-hidden link transition delay-300 ease-out duration-500 hover:highlight mdx:mr-0 mdx:pb-6 mdx:last:pb-0`,
  link: `font-sub font-bold text-clamp-sm transition-all delay-200 ease-out screensm:m-0 screensm:uppercase screensm:focus:outline-none mdx:text-xl`,

  group: `w-40 flex items-center justify-end mdx:w-full mdx:justify-center mdx:mt-16`,
}

const Nav = ({ datas }: Datas) => {
  const { clickMenu } = useMenuContext()

  const links = datas.allMarkdownRemark.edges[0].node.frontmatter.nav

  useEffect(() => {
    window.addEventListener('scroll', activeLink)

    return () => window.removeEventListener('scroll', activeLink)
  })

  const activeLink = () => {
    let current = ''
    const sections = document.querySelectorAll('.section')
    const links = document.querySelectorAll('.link')

    sections.forEach((section: any) => {
      const sectionTop = section.offsetTop
      const sectionHeight = section.clientHeight
      const sectionPos = sectionTop - sectionHeight / 3

      if (sectionTop !== null && window.scrollY >= sectionPos) {
        current = section.getAttribute('id')
      }
    })

    links.forEach((link: any) => {
      link.classList.contains(current)
        ? link.classList.add('active')
        : link.classList.remove('active')
    })
  }

  return (
    <nav className={s.nav}>
      <Logo filters={clickMenu} />
      <Hamburger filters={clickMenu} />
      <ul className={s.list(clickMenu)}>
        {links.map(({ id, slug, title, link }) => (
          <li key={id} className={`${s.items} ${slug.toLowerCase()}`}>
            <AnchorLink to={`/${link}`} stripHash className={s.link}>
              {title}
            </AnchorLink>
          </li>
        ))}
        <li className={s.group}>
          <Social filters={true} direction="flex-row" />
        </li>
      </ul>
    </nav>
  )
}

export default Nav
