import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

interface Datas {
  filters?: boolean
  direction: any
}

interface Social {
  id: number
  title: string
  link: string
  image: {
    src: {
      publicURL: string
    }
    alt: string
    width: number
    height: number
  }
}

const s = {
  container: (filters: any) => ` ${filters ? `invert-0` : `invert`}
  w-36 h-fit flexbetween transition-all delay-300 ease-in-out`,
  link: `mb-0`,
  image: `w-8 h-8 filtercolor`,
}

const Social = ({ filters, direction }: Datas) => {
  const datas = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/site/" } }) {
        edges {
          node {
            frontmatter {
              socials {
                id
                title
                link
                image {
                  alt
                  src {
                    publicURL
                  }
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  `)

  const socials = datas.allMarkdownRemark.edges[0].node.frontmatter.socials

  return (
    <section className={`${s.container(filters)} ${direction}`}>
      {socials.map(({ id, title, link, image }: Social) => (
        <a className={s.link} key={id} href={`${link}`} title={title}>
          <img
            className={s.image}
            src={image.src.publicURL}
            alt={image.alt}
            width={image.width}
            height={image.height}
          />
        </a>
      ))}
    </section>
  )
}

export default Social
