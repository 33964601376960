import React from 'react'

import Social from 'shared/Social/Social'

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        footer: {
          copy: string
        }
      }
    }
  }
}

const s = {
  container: `w-full h-fit bg-black`,
  flex: `flexbetween`,

  group: `flex items-center text-white text-sm`,
  text: `mb-0`,
  span: `mr-2 text-yellow`,
  copy: `-mb-0.5 ml-2 text-sm hover:text-red transition`,
}

const Copy = ({ datas }: Datas) => {
  const { copy } = datas.markdownRemark.frontmatter.footer

  return (
    <section className={s.container}>
      <div className={s.flex}>
        <div className={s.group}>
          <p className={s.text}>
            <span className={s.span}>{copy}</span>
            {`© ${new Date().getFullYear()}, Made by `}
          </p>
          <a className={s.copy} href="https://opdev.site/" target="_blank">
            JAck
          </a>
        </div>

        <Social filters={true} direction="row" />
      </div>
    </section>
  )
}

export default Copy
