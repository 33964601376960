import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useInView } from 'react-intersection-observer'

import Content from 'main/Footer/Content'
import Copy from 'main/Footer/Copy'
import Top from 'shared/Top/Top'

const s = {
  container: `w-full h-fit pt-32 px-0 pb-12 bg-black`,
  wrapper: `wrapper`,
}

const Footer = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          footer {
            copy
            header {
              id
              title
              description
            }
            navs {
              id
              title
              items {
                id
                title
                link
              }
            }
            contact {
              title
              items {
                id
                title
                text
              }
            }
          }
        }
      }
    }
  `)

  const [ref, inView] = useInView({
    threshold: 0.5,
  })

  return (
    <div ref={ref} className={s.container}>
      <div className={s.wrapper}>
        <Content datas={datas} />
        <Copy datas={datas} />

        {inView && <Top />}
      </div>
    </div>
  )
}

export default Footer
