import React from 'react'
import Markdown from 'markdown-to-jsx'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { AnimationFadeIn } from 'shared/Animation/Animation'

import Logo from 'shared/Logo/Logo'

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        footer: {
          id: number
          header: {
            id: number
            title: string
            description: string
          }
          navs: {
            id: number
            title: string
            items: {
              id: number
              title: string
              link: string
            }[]
          }
          contact: {
            title: string
            items: {
              id: number
              title: string
              text: string
            }[]
          }
        }
      }
    }
  }
}

const s = {
  container: `w-full h-fit pb-28`,
  grid: `grid-3 gap-12 mdx:grid-cols-1 mdx:gap-y-20`,
  flex: `w-full h-full flex flex-col text-white first:-mt-4`,

  title: `mb-6 text-3xl text-white`,
  description: `w-w28 mt-5 mb-0 text-base leading-normal lgx:w-5/6`,

  list: `list-none flex flex-col reserve reserve_foot`,
  items: `pb-6 text-white link hover:highlight`,
  subtitle: `-mb-2 text-2xl font-main`,
  text: `mb-0 info`,
}

const Content = ({ datas }: Datas) => {
  const { header, navs, contact } = datas.markdownRemark.frontmatter.footer

  return (
    <section className={s.container}>
      <AnimationFadeIn delay={200} x={0} y={0}>
        <div className={s.grid}>
          <div className={s.flex}>
            <Logo filters={true} />
            <p className={s.description}>{header.description}</p>
          </div>

          <div className={s.flex}>
            <h3 className={s.title}>{navs.title}</h3>
            <ul className={s.list}>
              {navs.items.map(({ id, title, link }) => (
                <li className={`${s.items} ${title.toLowerCase()}`} key={id}>
                  <AnchorLink className="" to={`/${link}`} stripHash>
                    {title}
                  </AnchorLink>
                </li>
              ))}
            </ul>
          </div>

          <div className={s.flex}>
            <h3 className={s.title}>{contact.title}</h3>
            <ul className={s.list}>
              {contact.items.map(({ id, title, text }) => (
                <li className={s.items} key={id}>
                  <p className={s.subtitle}>{title}</p>
                  <Markdown className={s.text}>{text}</Markdown>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </AnimationFadeIn>
    </section>
  )
}

export default Content
