import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

interface Datas {
  markdownRemark: {
    frontmatter: {
      sites: {
        title: string
        link: string
        image: {
          alt: string
          src: any
          width: number
          height: number
        }
      }
    }
  }
}

interface Filters {
  filters: boolean
}

const s = {
  container: `relative z-3 w-fit flex items-center`,
  link: `flex flex-row items-center uppercase font-sub font-bold text-h3 text-white`,
  title: (filters: any) => `${filters ? `invert-0` : `invert`} 
  text-black -mb-3 transition-all delay-300 ease-out duration-500`,
  image: `w-20 h-20 mr-2 filtercolor`,
}

const Logo = ({ filters }: Filters) => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          sites {
            title
            link
            image {
              alt
              width
              height
              src {
                publicURL
              }
            }
          }
        }
      }
    }
  `) as Datas

  const { title, link, image } = datas.markdownRemark.frontmatter.sites

  return (
    <div className={`${s.container} `}>
      <AnchorLink to={`/${link}`} stripHash className={s.link}>
        <img
          className={s.image}
          src={image.src.publicURL}
          alt={image.alt}
          width={image.width}
          height={image.height}
        />
        <h3 className={s.title(filters)}>{title}</h3>
      </AnchorLink>
    </div>
  )
}

export default Logo
